import React, { Component } from "react";
import {FaTwitter ,FaInstagram , FaMediumM , FaDribbble, FaLinkedinIn } from "react-icons/fa";
import Scrollspy from 'react-scrollspy'

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/mgiuzzi/'},
    {Social: <FaMediumM /> , link: 'https://medium.com/@teo155'},
    {Social: <FaDribbble /> , link: 'https://dribbble.com/teo155'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/giuzzidotcom/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/teo155'},  
]
class HeaderEmpty extends Component{
    constructor(props) {
        super(props);
        this.stickyHeader = this.stickyHeader.bind(this);

        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else{
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/big-logo.png" alt="Matteo Giuzzi logo" /></a>;
        }
        
        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper header-empty">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['']} currentClassName="is-current" offset={-200}>
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderEmpty;