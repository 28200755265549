import React, { Component } from "react";
import { FiCoffee , FiMessageCircle , FiUsers ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import { slideSlick, testimonialsSlideSlick } from "../page-demo/script";


const SlideList2 = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--23',
        category: '',
        title: "Matteo is a coach who cares about you from his heart.",
        description: "Needless to say, he shares and directs you with technical knowledge and skills from his experience professionally but most importantly, he communicates with you as a person sincerely. He is always fair and by your side as a great supporter who encourages and motivates you and celebrates your growth.",
        // buttonText: 'Contact Us',
        // buttonLink: '/contact'
        author: "Natsumi"
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--23',
        category: '',
        title: "A game-changing experience",
        description: "If you're seeking a coach who believes in your potential, Matteo is the right person! My journey took a transformative turn under his guidance. In every session, I sensed his sincerity and genuine commitment to help me navigate the challenges of stepping into the UX world. This kept me going through tough times and made me believe in myself. Besides that, Matteo was also very encouraging and generous in sharing valuable field insights and tips for acing interviews, and this has helped me a lot on my journey. With these qualities and his passion for helping others, every coaching session with him was an enlightening and insightful experience!",
        // buttonText: 'Contact Us',
        // buttonLink: '/contact'
        author: "Faye"
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--23',
        category: '',
        title: "I couldn't have made a better choice.",
        description: "Since I started taking coaching sessions with Matteo, I simply feel less stressed throughout my week and I can make more rational, balanced decisions both at work and in my life. Highly professional. I recommend him!",
        // buttonText: 'Contact Us',
        // buttonLink: '/contact'
        author: "Clare"
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--23',
        category: '',
        title: "Working on my growth with Matteo has been an incredible, successful journey.",
        description: "He helped me finding my first job with specific and insightful tips that I will never forget on how to improve my presence online and during interviews. I feel more confident in just a few months and it's thanks to him.",
        // buttonText: 'Contact Us',
        // buttonLink: '/contact'
        author: "Davide"
    }
]

class Values extends Component{
    render(){
        return(
            <React.Fragment>

                {/* Start Testimonia Area  */}
                <div className="testimonial-area bg_color--1 ptb--120">
                        <h2 class="title life-coach-title coach-subtitle">Some thoughts from the wonderful people that chose me as a coach</h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="slider-wrapper color-white">
                                    <div className="slider-activation slider-digital-agency">
                                        <Slider className="rn-slick-dot dot-light" {...testimonialsSlideSlick}>
                                            {SlideList2.map((value , index) => (
                                                <div className={`testimonials`}>
                                                    <div className="react-tabs" data-tabs="true">
                                                        <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel">
                                                            <div className={`rn-testimonial-content ${value.textPosition}`}>
                                                                <div className="inner">
                                                                {/* {value.category ? <span>{value.category}</span> : ''} */}
                                                                {value.title ? <p className="title">{value.title}</p> : ''}
                                                                {value.description ? <small className="description">{value.description}</small> : ''}
                                                                {/* {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                                                </div>
                                                                <div className="author-info" id="contact">
                                                                {value.author ? <h6>{value.author}</h6> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
                {/* End Testimonia Area  */}

            </React.Fragment>
        )
    }
}
export default Values;