import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterThree from "../component/footer/FooterThree";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <div className="active-dark">
                    <PageHelmet pageTitle='Contact' />

                    <HeaderThree headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                    {/* Start Breadcrump Area */}
                    <div className="rn-page-title-area pt--120 pb--120"  data-white-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title theme-gradient">Contact Me</h2>
                                        <p>Are you looking for some help with UX, design and strategy or you simply want to say hello? Let’s get in touch!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}


                    {/* Start Contact Top Area  */}
                    <div className="rn-contact-top-area pb--30 bg_color--5">
                        <div className="container">
                        
                            <div className="row">

                                {/* Start Single Address  */}
                                <div className="email-block col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiMail />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Email Address</h4>
                                            <p><a href="mailto:jirachi155@gmail.com">jirachi155@gmail.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                            </div>
                        </div>
                    </div>
                    {/* End Contact Top Area  */}

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                </div>
                
                <FooterThree />
                
            </React.Fragment>
        )
    }
}
export default Contact