const BlogContent = [
    {
        images: '01',
        title: ' OpenAI’s Swarm: A Glimpse into the Future of Collaborative AI Automation',
        category: 'Medium post',
        url: 'https://medium.com/@teo155/openais-swarm-a-glimpse-into-the-future-of-collaborative-ai-automation-469dbce306a0?sk=60e45564b5b32d9126ac0e9a68e019af'
    },
    {
        images: '02',
        title: ' Designers need codes, not keys, to unlock their career',
        category: 'Medium post',
        url: 'https://medium.com/@teo155/designers-need-codes-not-keys-to-unlock-their-career-94363691f463'
    },

    {
        images: '03',
        title: 'A storyboard is worth a thousand words',
        category: 'Medium post',
        url: 'https://medium.com/@teo155/a-storyboard-is-worth-a-thousand-words-7f7d45247d3f'
    }
]

export default BlogContent;