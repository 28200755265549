// React Required
import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// Home layout
import GiuzziDotCom from './dark/GiuzziDotCom';

// Element Layout
import Contact from "./elements/Contact";
import error404 from "./elements/error404";

// Portfolio Projects
import FAPortfolioDetails from "./elements/portfolio/Projects/FAPortfolioDetails"
import RoverPortfolioDetails from "./elements/portfolio/Projects/RoverPortfolioDetails"
import DogBuddyPortfolioDetails from "./elements/portfolio/Projects/DogBuddyPortfolioDetails"
import WorseIdeasPortfolioDetails from "./elements/portfolio/Projects/WorseIdeasPortfolioDetails"

// Extended Portfolio
import DogBuddyExtendedDetails from "./elements/portfolio/Projects//Extended/DogBuddyExtendedDetails"

// Life Coaching
import LifeCoaching from "./elements/lifecoaching/LifeCoaching"


import { BrowserRouter, Switch, Route, useNavigate, useHistory, Redirect  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const RedirectPage = () => {
    React.useEffect(() => {
      window.location.replace('https://www.google.com')
    }, [])
  }

  const RedirectToStatic = () => {
    useEffect(() => {
        window.location.href = `${process.env.PUBLIC_URL}/natale/buon_natale_2023.html`;
    }, []);

    return null;
};

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={GiuzziDotCom}/>

                    {/* Element Layout */}
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>

                    {/* Portfolio Projects */}
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details-footballalbum`} component={FAPortfolioDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details-rover`} component={RoverPortfolioDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details-dogbuddy`} component={DogBuddyPortfolioDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details-worse-ideas`} component={WorseIdeasPortfolioDetails}/>

                    {/* Extended Portfolio */}
                    <Route exact path={`${process.env.PUBLIC_URL}/dogbuddy-walkies-likes-more-details`} component={DogBuddyExtendedDetails}/>

                    {/* Life Coaching */}
                    <Route exact path={`${process.env.PUBLIC_URL}/coaching`} component={LifeCoaching}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/aaa`} component={() => {
                        // window.open('https://www.google.com', '_blank');
                        window.location.replace('/aaa/index.html');
                    }}/>

                    {/* <Route exact path={`${process.env.PUBLIC_URL}/nat`} component={() => {
                        // window.open('https://www.google.com', '_blank');
                        window.location.replace('/natale/buon_natale_2023.html');
                    }}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/natale-2023`} render={() => (
                        <Redirect to="/natale/buon_natale_2023.html" />
                    )} /> */}

                    <Route exact path={`${process.env.PUBLIC_URL}/natale-2023`} component={RedirectToStatic} />



                    {/* <Route exact path={`${process.env.PUBLIC_URL}/aaa`} component={() => {
                        // window.open('https://www.google.com', '_blank');
                        // useHistory.push('./apartment/index.html');
                        window.location.replace('/apartment/index.html');

                    }}/> */}



                    {/* Blocks Elements  */}
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                    {/* Extras */}
                    {/* <Route exact path="/aaa-apartment">
                    <iframe src="../public/apartment/index.html" />
                    </Route>  */}

                    {/* <Route path="/aaa-apartment" element={ <Navigate to="/apartment/index.html" /> } /> */}





                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();