import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaMediumM , FaDribbble, FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/mgiuzzi/'},
    {Social: <FaMediumM /> , link: 'https://medium.com/@teo155'},
    {Social: <FaDribbble /> , link: 'https://dribbble.com/teo155'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/giuzzidotcom/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/teo155'}, 
]
class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0 bg_color--4">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Have questions or want to collaborate?</span>
                                        <h2>👋 → ✉️</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Get in touch</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href={`${process.env.PUBLIC_URL}/#portfolio`}>Works</a></li>
                                                    <li><a href={`${process.env.PUBLIC_URL}/#about`}>About</a></li>
                                                    <li><a href="/contact">Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:jirachi155@gmail.com">jirachi155@gmail.com</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2023 Giuzzidotcom. All Rights Reserved.<br></br>Logo made by <a href="https://instagram.com/matteo_giuseppe_pani?igshid=voi5w9q4u88a" target="_blank">@matteo_giuseppe_pani</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;