import React from 'react';
import {FaTwitter ,FaInstagram ,FaMediumM , FaLinkedinIn, FaDribbble} from "react-icons/fa";

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/mgiuzzi/'},
    {Social: <FaMediumM /> , link: 'https://medium.com/@teo155'},
    {Social: <FaDribbble /> , link: 'https://dribbble.com/teo155'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/giuzzidotcom/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/teo155'},

]

const FooterThree = () => {
    return (
        <div className="footer-style-3 ptb--30">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="logos-row col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="text-center social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text text-center">
                            <p>Copyright © 2023 Giuzzidotcom. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterThree;