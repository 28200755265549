import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

import SliderFive from "../component/slider/SliderFive";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import AboutThree from "../component/HomeLayout/homeOne/AboutThree";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";


class GiuzziDotCom extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="Matteo Giuzzi Design" />
                <HeaderThree headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderFive />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div id="about" className="about-wrapper pb--50">
                    <AboutThree />
                    {/* <About /> */}

                    {/* Start CounterUp Area */}
                    <div className="rn-counterup-area pt--110">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="fontWeight500">Other Fun Facts</h3>
                                    </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div>
                    {/* End CounterUp Area */}

                    {/* Start Service Area  */}
                    <div className="service-area pt--110  bg_image bg_image--3">
                        <div className="container">
                                <ServiceTwo />
                        </div>
                    </div>
                    {/* End Service Area  */}
                </div>
                {/* End About Area */}

                {/* Start Portfolio Area */}
                <div id="portfolio" className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Blog Area */}
                <div id="blog" className="rn-blog-area pt--120 pb--150 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-left">
                                    <h2>Latest Blog Articles</h2>
                                    <p>I'm passionate about writing. Here are some of my past thoughts about Design and its joys and struggles. </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href="https://medium.com/@teo155" target="_blank" rel="noopener noreferrer"><span className="text">View All Articles</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href={value.url} target="_blank" rel="noopener noreferrer">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href={value.url} target="_blank" rel="noopener noreferrer">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={value.url} target="_blank" rel="noopener noreferrer">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
                {/* End Blog Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160} duration={0.3}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default GiuzziDotCom;