import React, { Component } from "react";
import PageHelmet from "../../../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../../../../component/footer/Footer";
import HeaderEmptyNoDownload from "../../../../component/header/HeaderEmptyNoDownload";

class DogBuddyExtendedDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        // const { title , parent} = this.props;
        return(
            <React.Fragment>
                <PageHelmet pageTitle='DogBuddy Portfolio Details' />

                <HeaderEmptyNoDownload headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details portfolio-details-extended">
                                    <div className="inner">
                                        <p className="project-role">DogBuddy</p>
                                        <h2 className="extended-title">Find Your Perfect Dog Sitter</h2>
                                        <h3 className="extended-subtitle">Walk Reports, Dog Likes and more...</h3>
                                        <div className="extended-content">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/00.png" alt="DogBuddy Intro Image"/>
                                            </div>
                                            <p>	When people need to trust your service even before start using it, you generally have two choices: a) pay their confidence by providing some sort of insurance or trial period or b) give them better tools and value as soon as they start using your Product. At DogBuddy, providing users with what they need "to feel home" is always our number 1 priority. This doesn't mean we've always built what users asked us. It's actually sometimes what they didn't know they needed.
                                                <br></br>
                                                <br></br>
                                                Walkies was a feature built with trust in mind that helped thousands of owners knowing where their dogs walked and for our long, with report cards provided by our sitters.
                                                <br></br>
                                                <br></br>
                                                Start a walk, let the GPS follow you around, send the report. It's as easy!
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <p className="subtitle">Walkies - Walks report, anywhere</p>
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/01.png" alt="DogBuddy Walkies Demo"/>
                                            </div>
                                            <p>	If you ever owned a pet, you know the struggle of going to work, on holiday, or sometimes simply out for dinner with friends without wanting to leave your furry pal alone. Owning a pet should be easy! DogBuddy is a peer-to-peer marketplace aiming to solve these exact uneasy situations by helping dogs, cats and their owners find trusted and local pet sitters.
                                                <br></br>
                                                <br></br>
                                                From walking your dogs during the day while you're at work/running errands to taking care of them 24/7 during your deserved 3 weeks yearly vacation, we help users find exactly what they need, when they need it.
                                                <br></br>
                                                <br></br>
                                                DogBuddy was present on Web, iOS and Android platforms and was live in 8 market until May 2019. It's been recently acquired by Rover.com, but our mission and promise to the users remains the same.
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <p className="subtitle">Dog Likes - Wishlist your future best job</p>
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/02.png" alt="DogBuddy Walkies Demo"/>
                                            </div>
                                            <p>	Historically, when people shop around in different type of marketplaces, the user that "wants to buy" has a fair advantage on who "wants to sell": they can bookmark their favourite items either for immediate or future use (listings on Airbnb, new upcoming videogames on Amazon, etc) and go back to them later if and when they'll need it. In DogBuddy, we wanted to give more power to the supply side too (dog sitters) so that they would be able to wishlist dogs in their area that they would have liked to take care of.
                                                <br></br>
                                                <br></br>
                                                In this way Dog Likes allowed us not only to offer a gamification of an otherwise standard shopping experience, but also to trigger push notifications for the dog owners to let them know that a sitter in their area would be ready for their puppy whenever they'll need it. A way of reminding owners that we're always in their pocket!
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <p className="subtitle">Apps redesign for search result cells</p>
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/03.png" alt="DogBuddy Walkies Demo"/>
                                            </div>
                                            <p>	Beautiful experiences don't always need beautiful UI. In the case of our original sitter search result cells though, we soon realised that UX and UI were equally not great at serving their purpose. Prioritisation of the information of the cell itself was almost entirely missing and the search results one under each other didn't really provide enough difersification for the owners to make a conscious decision on who to check out first. All sitters pretty much looked the same!
                                                <br></br>
                                                <br></br>
                                                Different studies led us to give more important to images (browsable directly from the cell - no need to enter the listing) and to reprioritise the amount of information and spacing of each cell. The result was a 35% increase in First Cell Tap to Contact and growing.
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <h3>And More!</h3>
                                            <p className="subtitle">Country and platform specific payment methods</p>
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/04.png" alt="DogBuddy Walkies Demo"/>
                                            </div>
                                            <p>	Being live for almost 4 years in 8 countries made us more sensible to the different user-base needs. Creating customised, country-based experiences became more and more important for us in order to thrive in all different market and don't leave anyone behind.
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <p className="subtitle">Fully featured website people love</p>
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/05.png" alt="DogBuddy Walkies Demo"/>
                                            </div>
                                            <p>	Our website <s>is</s> was baked with almost all the features that were present in the apps. As we grew, to remain agile and as efficient as possible we consciously decided to prioritise some features to be mobile only, while keeping all core functions available on all platforms (Web, iOS and Android).
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <p className="subtitle">App Store awards winning app</p>
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/Extended/DogBuddy/06.png" alt="DogBuddy Walkies Demo"/>
                                            </div>
                                            <p>	Finally, DogBuddy has been featured a number of time on the App Store (particularly for UK, but also Spain and Italy) and won the award of best app for its category in 2017.
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <p>	<strong>As of now, you can still browse on the homepage of the <a href="https://dogbuddy.com">DogBuddy</a> website, but any attempt to login or signup will redirect you to the Rover product that we're now part of.</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Related Work</span>
                                    <h2>Explore Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details-rover">
                                            <img src="/assets/images/portfolio/Rover/Rover_01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details-rover">Rover</a></h4>
                                        <span className="category">Role: Senior Product Designer</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="https://www.mediboost.it" target="_blank" rel="noopener noreferrer">
                                            <img src="/assets/images/portfolio/Mediboost/Mediboost_01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="https://www.mediboost.it" target="_blank" rel="noopener noreferrer">Mediboost.it</a></h4>
                                        <span className="category">Role: Co-Founder</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="rn-button-style--1 rn-btn" href={`${process.env.PUBLIC_URL}/#portfolio`}><span>View All Project</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  

            </React.Fragment>
        )
    }
}
export default DogBuddyExtendedDetails;
