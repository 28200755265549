import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-3',
        category: 'DogBuddy',
        title: ' Find your perfect dog sitter.',
        slug: 'dogbuddy',
        isDirect: false
    },
    {
        image: 'image-2',
        category: 'Rover',
        title: ' The world’s largest network of 5‑star pet sitters and dog walkers.',
        slug: 'rover',
        isDirect: false
    },
    {
        image: 'image-7',
        category: 'Agentive',
        title: ' The fastest way to build and deliver AI agents for your clients.',
        slug: 'agentive',
        isDirect: true,
        url: 'https://agentivehub.com'
    },
    {
        image: 'image-8',
        category: 'Meta',
        title: ' Shaping the future of work by growing the Meta for Work suite.',
        slug: 'meta',
        isNoLink: true
    },
    {
        image: 'image-4',
        category: 'Worse Ideas',
        title: ' Share your ideas to change the world.',
        slug: 'worse-ideas',
        isDirect: false,
        url: 'https://worseideas.s3.eu-west-1.amazonaws.com/index.html'
    },
    {
        image: 'image-5',
        category: 'Mediboost',
        title: " Boost your medical practice's online presence.",
        slug: 'mediboost',
        isDirect: true,
        url: 'https://mediboost.s3.eu-west-1.amazonaws.com/index.html'
    },
    {
        image: 'image-6',
        category: 'Dodo Codes Exchange',
        title: " Seamlessly Exchange Animal Crossing's dodo codes.",
        slug: 'dodo-exchange',
        isDirect: true,
        url: 'https://s3.amazonaws.com/www.giuzzi.com/appsdodos/index.html'
    },
    {
        image: 'image-1',
        category: 'FootballAlbum',
        title: ' Where football meets your social network.',
        slug: 'footballalbum',
        isDirect: false
    }
]

class Portfolio extends Component{
    render(){
        let title = 'My Works',
        description = "Projects I'm proud of- worked within a big team, small team or independent setup.";
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value, index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        {!value.isNoLink
                                            ? value.isDirect
                                                ? <a href={value.url} target="_blank" rel="noopener noreferrer">
                                                    <div className={`thumbnail ${value.image}`}></div>
                                                    <div className={`bg-blr-image ${value.image}`}></div>
                                                </a>
                                                : <a href={"/portfolio-details-" + value.slug}>
                                                    <div className={`thumbnail ${value.image}`}></div>
                                                    <div className={`bg-blr-image ${value.image}`}></div>
                                                </a>
                                            : <div style={{ pointerEvents: 'none' }}> {/* This prevents any clicking */}
                                                <div className={`thumbnail ${value.image}`}></div>
                                                <div className={`bg-blr-image ${value.image}`}></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4>
                                                {!value.isNoLink
                                                    ? value.isDirect
                                                        ? <a href={value.url} target="_blank">{value.title}</a>
                                                        : <a href={"/portfolio-details-" + value.slug}>{value.title}</a>
                                                    : <span>{value.title}</span>
                                                }
                                            </h4>
                                            <div className="portfolio-button">
                                                {value.isNoLink
                                                    ? <span className="rn-btn" style={{ color: 'white', fontStyle: 'italic' }}>Ask for more</span> // Show label when isNoLink is true
                                                    : (value.isDirect
                                                        ? <a className="rn-btn" href={value.url} target="_blank" rel="noopener noreferrer">Open Demo Website</a>
                                                        : <a className="rn-btn" href={"/portfolio-details-" + value.slug}>Open Project</a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;