import React, { Component } from "react";
import Scrollspy from 'react-scrollspy'

class HeaderEmpty extends Component{
    constructor(props) {
        super(props);
        this.stickyHeader = this.stickyHeader.bind(this);

        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else{
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/big-logo.png" alt="Matteo Giuzzi logo" /></a>;
        }
        
        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper header-empty">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block">
                            <Scrollspy className="mainmenu" items={['']} currentClassName="is-current" offset={-200}>
                                
                            </Scrollspy>
                            <div className="header-mobile">
                                    <p>Need a coach?</p>
                                    <div class="header-title">Matteo Giuzzi - Mentor and Product Designer <a class="header-contact-link" href="#contact">· <span>Get in touch!</span></a>
                                    </div>
                                </div>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderEmpty;