import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaMediumM ,FaDribbble, FaLinkedinIn } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy';

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/mgiuzzi/'},
    {Social: <FaMediumM /> , link: 'https://medium.com/@teo155'},
    {Social: <FaDribbble /> , link: 'https://dribbble.com/teo155'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/giuzzidotcom/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/teo155'},
]
class HeaderThree extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-dropdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else{
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/big-logo.png" alt="Matteo Giuzzi logo" /></a>;
        }
        
        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                                {logoUrl}
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['home','about','portfolio','blog','contact']} currentClassName="is-current" offset={-200}>
                                <li><a href={`${process.env.PUBLIC_URL}/`}>Home</a></li>
                                <li><a href={`${process.env.PUBLIC_URL}/#about`}>About</a></li>
                                <li><a href={`${process.env.PUBLIC_URL}/#portfolio`}>Portfolio</a></li>
                                <li><a href={`${process.env.PUBLIC_URL}/coaching`} target="_blank">Mentoring</a></li>
                                <li><a href={`${process.env.PUBLIC_URL}/#blog`}>Blog</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div className="header-btn">
                            <a className="rn-btn" target="_blank" href="https://www.giuzzi.com/assets/downloads/MG_Resume.pdf" download>
                                <span>Download Resume</span>
                            </a>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;