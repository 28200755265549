import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import TextLoop from "react-text-loop";


const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

class SliderOne extends Component {
  render() {
    return (
        <div id="home" className="slider-activation">
            {/* Start Single Slide */}
            {SlideList.map((value , index) => (
                <div className="slide personal-portfolio-slider mg-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--1" key={index}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`inner ${value.textPosition}`}>
                                    {value.category ? <span>{value.category}</span> : ''}
                                    <h1 className="title">Hi - I’m Matteo <br/>
                                    <TextLoop>
                                        <span>Product Strategy<br/>Consultant</span>
                                        <span> Product Designer</span>
                                        <span> Design Manager<br/>& Mentor</span>
                                        <span>Entrepreneur</span>
                                        {/* <span> Career Coach & Mentor</span> */}
                                        <span> Coder of Ideas</span>
                                    </TextLoop>{" "}
                                    </h1>
                                    <h2>based in... Planet Earth 🌍</h2>
                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;