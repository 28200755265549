import React, { Component } from "react";
import { FiCoffee , FiMessageCircle , FiUsers ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Footer from "../component/footer/Footer";


const ValuesList = [
    {
        icon: <FiCoffee />,
        title: 'Wherever You Are',
        description: 'Video calls with no location and time-zone boundaries.'
    },
    {
        icon: <FiMessageCircle />,
        title: 'Real-Time Help',
        description: 'Unlimited messages to ask for advice on the spot, in any situations of your life you might need it.'
    },
    {
        icon: <FiUsers />,
        title: 'Flexible Programs',
        description: 'Packages of 1 or 2 sessions per week to follow personalized objectives.'
    },
]
class Values extends Component{
    render(){
        return(
            <React.Fragment>

                {/* Start Service Area */}
                <div className="service-area ptb--50 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ValuesList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2 coaching-services">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title life-coach-values-title life-coach-subtitle">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

            </React.Fragment>
        )
    }
}
export default Values;