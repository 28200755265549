import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../../component/footer/Footer";
import HeaderCoaching from "../../component/header/HeaderCoaching";

import Quote from "../../blocks/Quote";
import ServiceCoaching from "../../elements/service/ServiceCoaching";
import LifeCoachingValues from "../../elements/LifeCoachValues";
import LifeCoachingTestimonials from "../../elements/LifeCoachTestimonials";

// import ContactO from "../../elements/contact/ContactOne";
// import ContactTw from "../../elements/contact/ContactTwo";
// import ContactTh from "../../elements/contact/ContactThree";
import LifeCoachingContact from "../../elements/contact/LifeCoachContact";
import FooterThree from "../../component/footer/FooterThree";



// import AboutWrapper from "../../component/HomeLayout/homeOne/About";



const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Need a Coach?',
        // title: 'Unlocking your <span>life and professional career</span> has never been more rewarding.',
        title: 'Balance is hard.<br>Improve or restart your <span>professional career & life</span> by getting all the support you need to grow.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]



class LifeCoaching extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        // this.stickybubbles = this.stickybubbles.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    // handleScroll(event) {
    //     window.onscroll = function(){
    //     if(window.scrollY >= 100) { // change target to number
    //         document.getElementsByClassName("chat-one").style.position = "relative";
    //     //    document.getElementsByClassName('chat-one').style.position = 'relative';
    //     }
    // };
    // }

    // handleScroll = e => {
    //     let element = e.target
    //     if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //       // do something at end of scroll
    //       console.log('ciao');
    //       if(window.scrollY >= 100) { // change target to number
    //                 document.getElementsByClassName("chat-one").style.position = "relative";
    //             //    document.getElementsByClassName('chat-one').style.position = 'relative';
    //             }
    //     }
    // }
    // stickybubbles() {
    //     window.onscroll = function(){
    //         var divo = document.getElementsByClassName("rn-testimonial-area")[0]

    //         var sticky = divo.offsetTop - 200;

    //         if (window.pageYOffset <= sticky) {
    //             document.getElementsByClassName("chat-one")[0].classList.add('sticky')
    //         } else {
    //             document.getElementsByClassName("chat-one")[0].classList.remove('sticky')

    //         }

    //         // var window_top = window.scrollTo(0,0);
    //         // var div_top = document.getElementsByClassName("chat-one")[0].offsetTop - 900;
    //         // console.log(window_top, div_top);
        
    //         // if(window.scrollY >= 600) { // change target to number
    //         //     document.getElementsByClassName("chat-one")[0].classList.remove('sticky')
    //         // //    document.getElementsByClassName('chat-one').style.position = 'relative';
    //         // } else if ()
         
    //      };
    // }
    componentDidMount() {
        // window.addEventListener('scroll', this.stickybubbles)
    }

    render(){
        // const { title , parent} = this.props;
        return(
            <React.Fragment>
                <div className="coaching">
                    <div className=""> 
                    <PageHelmet pageTitle='Matteo Giuzzi Life Coach' />

                    <HeaderCoaching headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                    </div>
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider-lc slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--lc" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title life-coach-title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="chat-bubbles">
                                <div className="chat-one sticky">
                                    <img src="/assets/images/lifecoaching/Chat001.png" alt="Chat 01"/>
                                </div>
                            </div> */}
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div class="about-wrapper bg_color--1 pt--60">
                    <div class="container">
                        <div class="row row--35 align-items-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="thumbnail"><img class="w-100" src="/assets/images/about/matteo02.jpg" alt="About Matteo"/></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="about-inner inner">
                                    <div class="section-title">
                                        <h2 class="title life-coach-title" >Develop yourself with the help of a coach who's there for you in times of need</h2>
                                        <div class="description">
                                            <p>Hello there! My name is <a href="https://www.linkedin.com/in/mgiuzzi/" target="_blank" >Matteo Giuzzi</a> and I'm a career coach and mentor with 12+ years of experience in Product Design, now working at <span><img width="33" src="/assets/images/portfolio/Meta/meta-logo.png" alt="Meta logo"/></span>Meta in one of the most exiting teams in the Reality Labs (VR) space. I've been working in tech startups small and large helping companies creating human, thoghtful product experiences.<br/><br/>My practice throughout the years, wearing multiple hats in multiple teams has helped me understand the behind the scenes perspective of the hiring process for designers as well as the importance of career and personal growth (and how difficult that can feel to be achieved).<br/><br/>My step-by-step approach to career exploration and evolution can help you make big shifts in your career and life. If you're looking for expert guidance, support or encouragement for your journey then you've come to the right place! Contact me if you have any questions!</p>
                                        </div>
                                    </div>
                                    {/* style="display: inline; text-decoration-line: underline; font-weight: normal;" */}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                {/* End About Area */}

                {/* Start Values Area */}
                <LifeCoachingValues item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                {/* End Values Area */}

                {/* Start Testimonia Area  */}
                <LifeCoachingTestimonials />
                {/* End Testimonia Area  */}

                <LifeCoachingContact />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterThree />  

            </div>
            </React.Fragment>
        )
    }
}
export default LifeCoaching;
